import React, {useEffect, useState} from "react";
import {
  Grid,
  Typography,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Phone from "../../images/phone.svg";
import Mail from "../../images/mail.svg";
import ChaiLightIcon from "../../images/portone_light.svg";
import ChaiDarkIcon from "../../images/portone_dark.svg";
import Camera from "../../images/camera.png";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Vietnam from "../../images/vietnam_flag.png";
import English from "../../images/uk.png";
import Thailand from "../../images/flag-thai.png";
import Singapore from "../../images/sgp.png";
import Indonesia from "../../images/id.png";
import Philippines from "../../images/ph.png";
import parse from 'html-react-parser';
import MailImage from "./mail";
import PhoneImage from "./phone";
// import ShareIcon from '@mui/icons-material/Share';
// import Whatsapp from "../../images/whatsapp.png";
// import Facebook  from "../../images/facebook.png";
import MailIcon from '@mui/icons-material/Mail';
import MessageIcon from '@mui/icons-material/Message';
import { FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { flexbox } from "@mui/system";

const Image = styled("img")(({ theme }) => ({
  width: "auto",
  height: "60px",

  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: "60px",
    //height: "100%",
  },
}));

const Dropdown = styled(Select)(({ theme }) => ({
  float: "right",
  height:"40px",
  //marginRight:"0rem",

  //padding: "5px",
  //border: "1px solid grey",
  borderRadius: "4px",
  '& .MuiSelect-select':{
    display:"flex",
    alignItems:"center",
    

  },
  '.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#707070"},
  

  [theme.breakpoints.down("sm")]: {
    float: "right",
    padding: "5px",
    border: "1px solid grey",
    borderRadius: "4px",
    marginRight:"1rem",
    '.MuiSvgIcon-root':{ width:"20px", height:"20px", color:"#707070;"},
  },
}));

const ChaiImage = styled("img")(({ theme }) => ({
  height: "25px",
  position: "relative",
  marginLeft:"4px",
  
  //   marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    marginLeft:"4px",
  },
}));

// const ImageIcon = styled("img")(({ theme }) => ({
//   width: "4%",
//   height: "4%",
//   padding:"0 2px 0 2px",
//   //   marginTop: "10px",
//   [theme.breakpoints.down("sm")]: {
//     width: "4%",
//     padding:"0 2px 0 2px",
//   },
// }));



const FlagImage = styled("img")(({ theme }) => ({
  width: "20px",
  height: "15px",
  marginRight:"5px",
  border:"1px solid black",
  //   marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "20px",
    border:"1px solid black",
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
      leftItem: {
      [theme.breakpoints.down("sm")]: {
        //marginBottom: "1rem",
      },
    },

    supportContainer:{
      // [theme.breakpoints.down("sm")]: {
      //   position: "absolute",
      //   top: "115rem",
        
      // },
      langSupport:{
        display:"flex",
        alignItems:"center",

      }

    },
    contact_us:{
      
        marginBottom: "20px !important"
        
      
    
    },

    langDropdown:{
      [theme.breakpoints.up("sm")]: {
        zIndex: "999999999",
      },
      [theme.breakpoints.down("sm")]: {
        //position: "absolute",
        zIndex: "999999999",
        right: "3%",
        top: "8%",
      }
    
        
      }

    
  })
);

const Common = (props) => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
 //const [language, setLanguage] = useState("en");
 
 var str1 = props.contentState.title;
 var pagelink = props.contentState.page_link;
 const socialUrl = window.location.href;


//  useEffect(()=>{
//   setLanguage(props.contentState.country_code && props.contentState.country_code.toLowerCase());
//  }, [])
  //localStorage.setItem("language", props.contentState.currency);
  
  // function changeLanguage(e) {
  //   setLanguage(e.target.value);
  //   i18n.changeLanguage(e.target.value);
  // }
  var str2 = "sms:?&body=Hey! Check out this Payment for " + str1 + " powered by PortOne. " + pagelink + "";
  var mailhref = "mailto:?subject=PORTONE PAYMENT LINK&body=Hey! Check out this Payment for " + str1 + " powered by PortOne. " + pagelink + "";
  var quote =  "Check out this Payment for " + str1 + " powered by PortOne. " + pagelink + "";
  return (
    <div className={classes.leftItem}>
      <Grid container alignItems={"center"} >
        

        
      </Grid>

      <Grid
        container
        alignItems="center"
        spacing={0}
        style={ props.contentState.is_dark_mode ? { marginTop: "1.5rem", color:"#C1C1C1" } :{marginTop:"1.5rem"}}
      >
        <Grid item xs={12} sm={10}>
          <Typography variant="h4" component="h4">
            {/* Comprehensive Health And Wellness Care For Employees */}
            {props.contentState.title}
          </Typography>
          <Typography variant="p" component={"p"} style={props.contentState.is_dark_mode ? { marginTop: "10px", fontSize:"15px", color:"#707070" }: {marginTop: "10px", fontSize:"15px"}}>
            {/* The Only Stopover That Provides The Full Range Of Business Welfare
            Services Needed */}
            <div id="descriptionpara">
              {parse(`${props.contentState.description}`)}
            </div>
          </Typography>
          {props.contentState.description?
          <span> <hr style={{ opacity:"0.2", borderTop: "dashed 1px" }}/></span>:<span></span>
          }



          { props.contentState.show_social_media_options && <Typography variant="h5" component={"h5"} style={ props.contentState.is_dark_mode ? { marginTop: "15px", fontSize:"15px", color:"#F7F4F4" } : {marginTop: "15px", fontSize:"15px"}}>
            Share This On:
          </Typography>}
          
          {props.contentState.show_social_media_options && <div style={{ display: "flex", alignItems: "center", justifyContent:"start", marginTop:"10px"}}>
          <a style={{ color: "black"}} href={mailhref} target="_blank" rel="noreferrer"><MailIcon sx={{ fontSize: 20, margin: "0px 2px", color:`${
                    props.contentState &&
                    props.contentState.merchant_details &&
                    props.contentState.merchant_details.brand_primary_color
                      ? props.contentState.merchant_details.brand_primary_color
                      : "black"
                  }` }} /></a>
          <a style={{ color: "black"}} href={str2} target="_blank" rel="noreferrer"><MessageIcon sx={{ fontSize: 20, color: `${
                    props.contentState &&
                    props.contentState.merchant_details &&
                    props.contentState.merchant_details.brand_primary_color
                      ? props.contentState.merchant_details.brand_primary_color
                      : "#000"
                  }` }} /></a>
          {/* { language === "th" && <div style={{ marginLeft: "3px"}}>
          <LineShareButton url={socialUrl} title={props.contentState.title}>
          <LineIcon size={20} round={true} />
          </LineShareButton>
          </div>} */}
          {<div style={{ marginLeft: "3px"}}>
          <FacebookShareButton url={socialUrl} title={props.contentState.title} quote={quote}>
          <FacebookIcon size={20} round={true} />
          </FacebookShareButton>
          </div>}
          
          {
          <div style={{ marginLeft: "3px"}}>
          <WhatsappShareButton url={socialUrl} title={props.contentState.title} separator={quote}>
          <WhatsappIcon size={20} round={true} />
          </WhatsappShareButton>
          </div>
          }

          {<div style={{ marginLeft: "3px"}}>
          <TwitterShareButton url={socialUrl} title={props.contentState.title} via={quote}>
          <TwitterIcon size={20} round={true} />
          </TwitterShareButton>
          </div>
          }
          
          </div>
          }
          
          {  props.contentState.show_social_media_options ? <span> <hr style={{ opacity:"0.2", borderTop: "dashed 1px" }}/></span> : <span></span>}

        {window.innerWidth > 992 &&
          <div className={classes.supportContainer}>
            {props.contentState &&
            props.contentState.merchant_details &&
            props.contentState.merchant_details.phone_number ? (
              <Typography
                variant="h5"
                component={"h5"}
                style={ props.contentState.is_dark_mode ?  { marginTop: "15px", padding:"9px 0", fontSize:"15px", color:"#F7F4F4" } :{marginTop: "15px", padding:"9px 0", fontSize:"15px"}}
              >
                {t("Contact Us")}
              </Typography>
            ) : props.contentState &&
              props.contentState.merchant_details &&
              props.contentState.merchant_details.email_address ? (
              <Typography
                variant="h5"
                component={"h5"}
                style={ props.contentState.is_dark_mode ?  { marginTop: "15px", padding:"9px 0", color:"#F7F4F4" }: {marginTop: "15px", padding:"9px 0"}}
              >
                {t("Contact Us")}
              </Typography>
            ) : (
              ""
            )}
                      
            <Typography variant="h6" component={"h6"} className={classes.contact_us} >
              <div style={ props.contentState.is_dark_mode ? { display: "flex", alignItems: "center", color:"#707070"} : {display: "flex", alignItems: "center"}}>
              {props.contentState &&
              props.contentState.merchant_details &&
              props.contentState.merchant_details.phone_number ? 
              // <img src={Phone} alt="phoneicon" style={{ marginRight: "5px", color: `${
              //   props.contentState &&
              //   props.contentState.merchant_details &&
              //   props.contentState.merchant_details.brand_primary_color
              //     ? props.contentState.merchant_details.brand_primary_color
              //     : "#000"
              // }` }} />
                <PhoneImage isdark = {props.contentState.is_dark_mode} color1 = {`${
                  props.contentState &&
                  props.contentState.merchant_details &&
                  props.contentState.merchant_details.brand_primary_color
                    ? props.contentState.merchant_details.brand_primary_color
                    : "#000"
                }`} />
              :
                ""
              }{" "}
              {props.contentState &&
                props.contentState.merchant_details &&
                props.contentState.merchant_details.phone_number}
              </div>
              <Typography variant="h6" component={"h6"} style={{ marginTop: "8px", display:"flex", alignItems:"center" }}>
                {props.contentState &&
                props.contentState.merchant_details &&
                props.contentState.merchant_details.email_address ? 
                  // <img src={Mail} alt="mailicon" style={{ marginRight: "3px", color: `${
                  //   props.contentState &&
                  //   props.contentState.merchant_details &&
                  //   props.contentState.merchant_details.brand_primary_color
                  //     ? props.contentState.merchant_details.brand_primary_color
                  //     : "#000"
                  // }` }} />
                  <MailImage isdark = {props.contentState.is_dark_mode}
                  color1={`${
                      props.contentState &&
                      props.contentState.merchant_details &&
                      props.contentState.merchant_details.brand_primary_color
                        ? props.contentState.merchant_details.brand_primary_color
                        : "#000"
                    }` } />
                : 
                  ""
                }{" "}
                {props.contentState &&
                  props.contentState.merchant_details &&
                  props.contentState.merchant_details.email_address}
              </Typography>
            </Typography>
            {props.contentState &&
              props.contentState.merchant_details &&
              (props.contentState.merchant_details.phone_number || props.contentState.merchant_details.email_address) ?
            <span> <hr style={{ opacity:"0.2", borderTop: "dashed 1px" }}/></span> : <span></span>}
            { props && props.contentState && props.contentState.terms_and_conditions !== "" ?
            <>
            <Typography
              variant="h5"
              component="h5"
              style={ props.contentState.is_dark_mode ?  { marginTop: "20px", marginBottom: "15px", fontSize:"15px", color:"#F7F4F4" }: {marginTop: "15px", marginBottom: "10px", fontSize:"15px"}}
            >
              {t("Terms And Conditions")}
            </Typography>
            <Typography variant="h6" component={"h6"} style={props.contentState.is_dark_mode ?  { color:"#707070",marginBottom: "20px"} : {marginBottom: "20px"}}>
            <div id="tandcpara">
              {parse(`${props.contentState.terms_and_conditions}`)}
            </div>
            </Typography>
            </> : ""}

            {props.contentState && props.contentState.terms_and_conditions ? <span> <hr style={{ opacity:"0.2", borderTop: "dashed 1px" }}/></span> : <span></span>}

            
              <Typography style={{ marginTop: "20px" }}>
                <div style={props.contentState.is_dark_mode ?{ color:"#707070", display:"flex", alignItems:"center", fontSize:"small", marginBottom: "8px" }: {display:"flex", alignItems:"center", fontSize:"small", marginBottom: "8px"}}>
              Powered by
              {props.contentState.is_dark_mode ?
              <ChaiImage src={ChaiDarkIcon} alt="chaiicon" /> :
              <ChaiImage src={ChaiLightIcon} alt="chaiicon" />}
              </div>
            </Typography>
            <Typography variant="h6" component={"h6"} style={ props.contentState.is_dark_mode ?  { color:"#707070", fontSize:"0.8rem"} :{fontSize:"0.8rem"}}>
              {t("Want to create page like this for your Business?")}
              <br></br>
              <a
                href="https://www.docs.portone.cloud/docs"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="p"
                  component={"span"}
                  style={{ color: "#1492E6" }}
                >
                  {" "}
                  {t("Payment pages docs")}{" "}
                </Typography>
              </a>
              {t("to get started!")}
            </Typography>
            {/* <Typography variant="p" component="p" style={{ marginTop: "10px" }}>
              <u>Report Page</u>
            </Typography> */}
          </div>}
        </Grid>
      </Grid>
    </div>
  );
};

export default Common;
